<template>
  <div
    ref="wrapperRef"
    class="form-field form-input-text form-field"
    :class="{
      'has-content': hasContent || hasFocus,
      'has-error': errors.length > 0,
    }"
  >
    <label class="form-input__label" :for="data.field_key">
      {{
        data.label
          + (data.is_required ? '*' : '')
      }}
    </label>

    <input
      :id="data.field_key"
      ref="fieldRef"
      :name="data.field_key"
      type="text"
      :disabled="isDisabled"
      :required="data.is_required"
      class="form-input__element"
      @input="handleInput($event)"
      @focus="setFocus(true)"
      @focusout="setFocus(false)"
      @keyup="isAutoComplete ? throttledExecute(() => {
        setAutocomplete($refs?.fieldRef?.value);
      }) : '';"
    >
    <div
      v-if="autoComplete?.length > 0 && isOpen"
      ref="autocompleteRef"
      class="form-input__autocomplete"
      :class="{
        'background-white': isBackgroundWhite,
      }"
      @scroll="handleLoadMore"
    >
      <ul>
        <li
          v-for="item in autoComplete"
          :key="item"
        >
          <SlotLink
            class="form-link__link"
            :url="item.type === 'product' ? `produkte/${item.slug || item.sb_slug}` : (item.slug || item.sb_slug)"
          >
            {{ item.name || item.title }}
          </SlotLink>
        </li>
        <li ref="loadMoreVisible" class="load_more_visible" />
      </ul>
    </div>
    <FormFieldErrors :errors="errors" />
  </div>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core';

const emit = defineEmits(['emitValue', 'hasErrors']);

const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },

    prefillValue: {
        type: String,
        default: '',
    },

    isAutoComplete: {
        type: Boolean,
        default: false,
    },

    isBackgroundWhite: {
        type: Boolean,
        default: false,
    },

    isGeneralAutoComplete: {
        type: Boolean,
        default: true,
    },

    isDisabled: {
        type: Boolean,
        default: false,
    },

    initialValue: {
        type: String,
        default: '',
    },
});

const { buildValidationObject } = useFormValidation();

const {
    hasFocus,
    hasContent,
    errors,
    handleEmit,
    setValue,
    setFocus,
} = useFormField(emit, buildValidationObject(props.data));

const { throttledExecute } = useThrottledExecute();
const handleInput = ($event) => {
    if (props.isGeneralAutoComplete) {
        setValue($event);
        handleEmit($event, props.data.field_key);
    } else {
        throttledExecute(() => {
            setValue($event);
            handleEmit($event, props.data.field_key);
        });
    }
};

/*
  data to prefill the field with
*/
const fieldRef = ref(null);

onMounted(() => {
    if (props.prefillValue) {
        const { prefillValue } = { ...props };
        fieldRef.value.value = prefillValue;
        setValue(prefillValue);
        handleEmit(prefillValue, props.data.field_key);
    }
});

watch(() => props.prefillValue, (newValue) => {
    if (newValue === null) {
        fieldRef.value.value = '';
        setValue('');
        handleEmit('', props.data.field_key);
    } else {
        fieldRef.value.value = newValue;
        setValue(newValue);
        handleEmit(newValue);
    }
});

/*
  Search Autocomplete functionality
*/
const autoComplete = ref(null);
const page = ref(1);
const perPage = ref(40);
const isOpen = ref(false);
const wrapperRef = ref(null);

/* Set autocomplete data */
const setAutocomplete = async (searchString, merge = false) => {
    try {
        const { public: publicConfig } = useRuntimeConfig();
        if (searchString?.length >= 3) {
            const { data: algData } = await useAsyncAlgoliaSearch({
                indexName: publicConfig.ALGOLIA_INDEX_NAME,
                query: searchString,
                requestOptions: {
                    offset: (page.value - 1) * perPage.value,
                    hitsPerPage: perPage.value,
                    facets: ['brands', 'manufacturers', 'attributes', 'therapy_forms', 'pharmaceutical_forms', 'product_categories', 'indications', 'product_tags', 'focus_topics'],
                },
            });
            const items = algData.value.hits.filter((i) => i.slug || i.sb_slug);
            isOpen.value = true;
            autoComplete.value = merge ? [
                ...autoComplete.value,
                ...items,
            ] : items;
        } else {
            isOpen.value = false;
            autoComplete.value = null;
        }
    } catch (e) {
        useSentryError(e);
    }
};

/* Close dropdown on clickoutside */
const autocompleteRef = ref(null);
onClickOutside(autocompleteRef, () => {
    if (isOpen.value) {
        isOpen.value = false;
    }
});

const handleLoadMore = () => {
    const container = autocompleteRef.value;
    if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
        page.value += 1;
        setAutocomplete(fieldRef.value.value, true);
    }
};
</script>

<style lang="scss" scoped>
.form-input__element {
    @include form-input-element;

    .shop-search-hero & {
        background: transparent;
    }

}

.form-input__label {
    @include form-input-label;

}

.form-input-text {
    @include form-input-wrapper;
}

.form-input__autocomplete {
    @include z-index('autocomplete');
    @include remove-scrollbars();

    position: absolute;
    top: 54px;
    overflow: auto;
    width: 100%;
    max-height: 40vh;
    border: 2px solid $C_PRIMARY;
    border-top: none;
    background-color: $C_GREEN_BRIGHT;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    &.background-white {
        background-color: $C_WHITE;
    }

    li {
        @include typo-size('form');
        @include typo-font('bold');

        padding: 12.5px 24px;
        color: $C_GREEN_DARK;

        &:hover {
            background: $C_GREEN_LIGHT;
            cursor: pointer;
        }
    }

    @include tablet {
        top: 44px;
    }
}
</style>
